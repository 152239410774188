export default class SerumPackCompositionProduct {
    constructor({ name, description1, description2, usage, price, multiplier, percentage, amount }) {
      this.name = name;
      this.description1 = description1;
      this.description2 = description2;
      this.usage = usage;
      this.price = price;  
      this.percentage = percentage;
      this.amount = amount;
      this.multiplier = multiplier;
    }
  }
