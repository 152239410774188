const ORDERS_STATUSES = {
  TREATMENT_PAID_OFFLINE: 'TREATMENT_PAID_OFFLINE',
  TREATMENT_PAID_ONLINE: 'TREATMENT_PAID_ONLINE',
  TREATMENT_FOR_FREE: 'TREATMENT_FOR_FREE',
  TREATMENT_OFFER_CREATED: 'TREATMENT_OFFER_CREATED'
};

const ORDERS_STATUSES_LABELS = {
  [ORDERS_STATUSES.TREATMENT_PAID_OFFLINE]: 'Offline (Paid)',
  [ORDERS_STATUSES.TREATMENT_PAID_ONLINE]: 'Online (Paid)',
  [ORDERS_STATUSES.TREATMENT_FOR_FREE]: 'Offline (Not paid)',
  [ORDERS_STATUSES.TREATMENT_OFFER_CREATED]: 'Online (Offer sent)'
};

const ORDERS_DELIVERY_STATUSES = {
  CANCELLED: 'CANCELLED',
  UNDER_PREPARATION: 'UNDER_PREPARATION',
  RESEND_OPERATION: 'RESEND_OPERATION',
  DELIVERED: 'DELIVERED'
};

const ORDERS_DELIVERY_STATUS_LABELS = {
  [ORDERS_DELIVERY_STATUSES.CANCELLED]: 'Cancelled',
  [ORDERS_DELIVERY_STATUSES.UNDER_PREPARATION]: 'Under preparation',
  [ORDERS_DELIVERY_STATUSES.RESEND_OPERATION]: 'Resend operation',
  [ORDERS_DELIVERY_STATUSES.DELIVERED]: 'Delivered'
};

const ORDER_DELIVERY_STATUS_CLASSES = {
  [ORDERS_DELIVERY_STATUSES.CANCELLED]: 'error--text',
  [ORDERS_DELIVERY_STATUSES.UNDER_PREPARATION]: 'warning--text',
  [ORDERS_DELIVERY_STATUSES.RESEND_OPERATION]: 'warning--text',
  [ORDERS_DELIVERY_STATUSES.DELIVERED]: 'success--text'
};

const ORDER_FIELD = {
  ID: 'orderId',
  STATUS: 'status',
  ODOO_ORDER_ID: 'odooOrderId',
  ODOO_ORDER_NAME: 'odooOrderName',
  PURCHASE_DATE: 'purchaseDate',
  CREATION_DATE: 'creationDate',
  DOCTOR_ID: 'doctorId',
  PATIENT_ID: 'patientId',
  DOCTOR_NAME: 'doctorName',
  CUSTOMER_NAME: 'customerName',
  LOGISTIC_CENTER_NAME: 'logisticCenterName',
  CARRIER: 'carrier',
  TRACKING_NUMBER: 'trackingNumber',
  TRACKING_NUMBER_REGISTRATION: 'trackingNumberRegistrationDate',
  CONSULTATION_MINUTES_SPENT: 'consultationMinutesSpent',
  FEE: 'doctorFee',
  FEE_CURRENCY_CODE: 'feeCurrencyCode',
  APPLIED_PROMOCODE_AMMOUNT: 'appliedPromoCodeAmount',
  AMOUNT: 'amount',
  CURRENCY: 'currency',
  HAS_INVOICE: 'hasInvoice',
  SENT_DATE: 'sentDate',
  DELIVERED_DATE: 'deliveredDate',
  TAXES: 'percentage',
  APPLIED_PROMO_CODE: 'appliedPromoCode',
  LOGISTIC_CENTER_ID: 'logisticCenterId',
  CANCELLED_COMMENT: 'canceledComment',
  HAS_ATTACHMENT: 'hasAttachment',
  IS_TEST: 'testOrder'
};

export {
  ORDERS_STATUSES,
  ORDERS_STATUSES_LABELS,
  ORDERS_DELIVERY_STATUSES,
  ORDERS_DELIVERY_STATUS_LABELS,
  ORDER_DELIVERY_STATUS_CLASSES,
  ORDER_FIELD
};
