<template>
  <div class="mt-5">
    <data-table
      :headers="$options.invoiceHeadersConfig"
      :items="orderProducts"
      disable-pagination
      hide-default-footer
    >
      <template v-slot:item.name="{ item }">
        <div>
          {{ item.name }}
        </div>
        <div class="d-flex flex-column grey--text description">
          <span>{{ item.description }}</span>
          <span>{{ item.description1 }}</span>
          <span>{{ item.description2 }}</span>
        </div>
      </template>

      <template v-slot:item.multiplier="{ item }">
        <div>{{ item.multiplier }}</div>
      </template>

      <template v-slot:item.price="{ item }">
        <div>
          {{ item.price }}
        </div>
      </template>
    </data-table>
    <hr />
    <div class="footer-total d-flex flex-column">
      <div class="d-flex">
        <div class="footer-total__label">Subtotal</div>
        <div class="font-weight-medium">{{ treatment.currencyCode }} {{ treatment.amount }}</div>
      </div>
      <div v-if="treatment.deliveryPrice" class="d-flex mt-3">
        <div class="footer-total__label">Shipping</div>
        <div class="font-weight-medium">
          {{ treatment.currencyCode }} {{ treatment.deliveryPrice }}
        </div>
      </div>
      <div v-if="treatment.appliedPromoCodeAmount" class="d-flex mt-3">
        <div class="footer-total__label">Promo code</div>
        <div class="font-weight-medium">
          {{ treatment.currencyCode }}
          {{ treatment.appliedPromoCodeAmount }}
        </div>
      </div>
      <div class="d-flex mt-3">
        <div class="footer-total__label">Order total</div>
        <div class="font-weight-medium blue--text">
          {{ treatment.currencyCode }} {{ orderTotal }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { chain, isEmpty } from 'ramda';

import DataTable from '@/components/common/DataTable.vue';

import CompositionProduct from '@/models/CompositionProduct';
import SerumPackCompositionProduct from '@/models/SerumPackCompositionProduct';

import { getArchiveTreatment } from '@/api/treatment.api';

import { getFullBottleDescription } from '@/utils/treatment';

import {
  TREATMENT_COMPOSITION_SECTION_NAMES,
  SERUM_USAGE_KEYS,
  SERUM_USAGE_ABBREVIATION,
  TREATMENT_PRODUCT_STATUS
} from '@/constants/treatment';

const INVOICE_TABLE_HEADER_CONFIG = [
  { text: 'Product', value: 'name', width: '410px' },
  { text: 'Quantity', value: 'multiplier', width: '160px' },
  { text: 'Price', value: 'price', width: '160px' },
  { text: 'Taxes', value: 'percentage', width: '160px' },
  { text: 'Amount', value: 'amount', width: '160px' }
];

export default {
  name: 'InvoiceDetailsTab',
  components: { DataTable },
  invoiceHeadersConfig: INVOICE_TABLE_HEADER_CONFIG,
  props: {
    order: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      treatment: {}
    };
  },
  computed: {
    orderProducts() {
      return this.getProducts(this.treatment);
    },
    orderTotal() {
      const { appliedPromoCodeAmount, deliveryPrice, orderPaidAmount, amount } = this.treatment;
      const shouldShowOrderPaidAmount = appliedPromoCodeAmount || deliveryPrice;

      return shouldShowOrderPaidAmount ? orderPaidAmount : amount;
    },
    orderTax() {
      const { percentage } = this.order;

      return percentage;
    }
  },
  mounted() {
    this.getTreatment();
  },
  methods: {
    async getTreatment() {
      const { treatmentId } = this.order;
      const { data } = await getArchiveTreatment({ treatmentId });

      this.treatment = data;
    },
    getProducts({ treatmentDetails }) {
      if (!treatmentDetails) {
        return [];
      }

      const { treatFace, treatEyes, cleanse, moisturize, protect } = treatmentDetails;

      const treatSections = [
        { section: treatFace, name: TREATMENT_COMPOSITION_SECTION_NAMES.TREAT_FACE },
        { section: treatEyes, name: TREATMENT_COMPOSITION_SECTION_NAMES.TREAT_EYES }
      ];

      const complementaryCareSections = [
        { section: cleanse, name: TREATMENT_COMPOSITION_SECTION_NAMES.CLEANSE },
        { section: moisturize, name: TREATMENT_COMPOSITION_SECTION_NAMES.MOISTURIZER },
        { section: protect, name: TREATMENT_COMPOSITION_SECTION_NAMES.PROTECT }
      ];

      const treatSectionsComposition = chain(
        ({ section, name }) => this.getTreatComposition(section, name),
        treatSections
      );

      const complementaryCareComposition = chain(
        ({ section, name }) => this.getComplementaryCareProductsComposition(section, name),
        complementaryCareSections
      );

      return [...treatSectionsComposition, ...complementaryCareComposition];
    },
    getTreatComposition(treat, compositionName) {
      const { cost, formulasOptions, status } = treat;

      if (!formulasOptions) {
        return null;
      }

      const { twoFormulas, oneFormula } = formulasOptions;

      if (twoFormulas.isFound) {
        return [...this.getFormulaComposition(twoFormulas, cost, compositionName, status)];
      }

      if (oneFormula.isFound) {
        return [this.getFormulaComposition(oneFormula, cost, compositionName, status)];
      }

      return [];
    },
    getFormulaComposition(formula, cost, compositionName, status) {
      if (status === TREATMENT_PRODUCT_STATUS.REMOVED_BY_PATIENT) {
        return [];
      }

      const { usage, compositionBottle1, compositionBottle2 } = formula;
      const price = parseFloat(cost);

      if (usage === SERUM_USAGE_KEYS.ONE_MORNING_ONE_EVENING) {
        const firstDescription = getFullBottleDescription(
          compositionBottle1,
          SERUM_USAGE_ABBREVIATION[SERUM_USAGE_KEYS.MORNING_ONLY]
        );
        const secondDescription = getFullBottleDescription(
          compositionBottle2,
          SERUM_USAGE_ABBREVIATION[SERUM_USAGE_KEYS.EVENING_ONLY]
        );

        const priceWithoutTax = this.getPriceWithoutTax(price);

        return [
          new SerumPackCompositionProduct({
            name: 'Face serum pack',
            description1: firstDescription,
            description2: secondDescription,
            price: `${this.treatment.currencyCode} ${priceWithoutTax}`,
            multiplier: 1,
            percentage: this.orderTax,
            amount: `${this.treatment.currencyCode} ${price}`
          })
        ];
      }

      if (
        compositionName === TREATMENT_COMPOSITION_SECTION_NAMES.TREAT_FACE &&
        usage !== SERUM_USAGE_KEYS.ONE_MORNING_ONE_EVENING
      ) {
        const firstDescription = getFullBottleDescription(
          compositionBottle1,
          SERUM_USAGE_ABBREVIATION[usage]
        );
        const secondDescription = getFullBottleDescription(
          compositionBottle2,
          SERUM_USAGE_ABBREVIATION[usage]
        );

        const priceWithoutTax = this.getPriceWithoutTax(price);

        return new SerumPackCompositionProduct({
          name: 'Face serum pack',
          description1: firstDescription,
          description2: secondDescription,
          price: `${this.treatment.currencyCode} ${priceWithoutTax}`,
          multiplier: 1,
          percentage: this.orderTax,
          amount: `${this.treatment.currencyCode} ${price}`
        });
      }

      const formulaBottle = compositionBottle1 || compositionBottle2;
      const formulaBottleDescription = getFullBottleDescription(
        formulaBottle,
        SERUM_USAGE_ABBREVIATION[usage]
      );
      const eyeContourPriceWithoutTax = this.getPriceWithoutTax(price);

      return new CompositionProduct({
        name: 'Eye contour serum',
        description: formulaBottleDescription,
        usage: SERUM_USAGE_ABBREVIATION[usage],
        price: `${this.treatment.currencyCode} ${eyeContourPriceWithoutTax}`,
        multiplier: 1,
        percentage: this.orderTax,
        amount: `${this.treatment.currencyCode} ${price}`
      });
    },
    getComplementaryCareProductsComposition(complementaryCare) {
      const { products } = complementaryCare;

      if (isEmpty(products) || !Array.isArray(products)) {
        return [];
      }

      return products
        .filter(product => product.status !== TREATMENT_PRODUCT_STATUS.REMOVED_BY_PATIENT)
        .map(product => {
          const { orderMultiplier, price } = product;
          const priceWithoutTax = this.getPriceWithoutTax(price) * orderMultiplier;
          const amount = orderMultiplier * price;

          return new CompositionProduct({
            name: `${product.name}, ${product.size}ml`,
            price: `${this.treatment.currencyCode} ${priceWithoutTax}`,
            multiplier: orderMultiplier,
            percentage: this.orderTax,
            amount: `${this.treatment.currencyCode} ${amount}`
          });
        });
    },
    getPriceWithoutTax(price) {
      const taxValue = 1 + this.orderTax / 100;

      return (price / taxValue).toFixed(2);
    }
  }
};
</script>

<style lang="scss" scoped>
.description {
  font-size: 12px;
}

.footer-total {
  position: relative;
  left: 956px;

  &__label {
    width: 150px;
  }
}
</style>
