<template>
  <base-modal
    width="400"
    action-button-label="Confirm"
    :action-button-loading="isLoading"
    v-bind="$attrs"
    @cancel="onCancel"
    @action-button-click="onActionButtonClick"
    v-on="$listeners"
  >
    <template #modal-title>
      <v-card-title class="px-0 pt-9 pb-5">
        <h1 class="title font-weight-regular text-center text-break">
          Make sure the order is ready to be delivered
        </h1>
      </v-card-title>
    </template>
  </base-modal>
</template>

<script>
import BaseModal from '@/components/common/BaseModal.vue';

import { orderForceDelivery } from '@/api/orders.api';

export default {
  name: 'ConfirmOrderForceDelivery',
  components: { BaseModal },
  props: {
    orderId: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      isLoading: false
    };
  },
  methods: {
    onCancel() {
      this.$emit('close-modal');
    },
    async onActionButtonClick() {
      this.isLoading = true;

      await orderForceDelivery({ orderId: this.orderId });

      this.$emit('force-delivery-activated');
    }
  }
};
</script>
