<template>
  <section>
    <div class="tab d-flex justify-space-between">
      <div v-for="{ fields, key } in columns" :key="key" class="tab__column mr-2">
        <div
          v-for="{
            label,
            value,
            isNone,
            valueClasses,
            route,
            comment,
            hasAttachmentField
          } in fields"
          :key="label"
          class="mb-5"
        >
          <div class="grey--text text--darken-1">{{ label }}</div>
          <template v-if="!isNone">
            <router-link
              v-if="route"
              class="text-decoration-none"
              :class="valueClasses"
              :to="route"
            >
              {{ value }}
            </router-link>
            <div v-else class="text-decoration-none" :class="valueClasses">
              {{ value }}
            </div>
            <div v-if="comment" class="font-italic mt-1 tab__comment">{{ comment }}</div>
            <div v-if="hasAttachmentField">
              <span
                class="light-blue--text text--darken-4 font-weight-medium text-decoration-underline mt-1 cursor-pointer"
                @click="onAttachmentClick"
              >
                {{ attachmentTitle }}
              </span>
              <v-icon
                v-if="sholudDisplayRemoveAttachmentButton"
                class="ml-1 cursor-pointer"
                color="red"
                size="18px"
                @click="onDeleteAttachment"
                >mdi-delete-forever</v-icon
              >
            </div>
          </template>
          <div v-else class="grey--text text--lighten-1 font-italic">none</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { pathOr, identity } from 'ramda';

import {
  ORDER_FIELD,
  ORDERS_DELIVERY_STATUS_LABELS,
  ORDER_DELIVERY_STATUS_CLASSES,
  ORDERS_DELIVERY_STATUSES
} from '@/constants/orders';

import DateFormatService from '@/services/DateFormatService';
import { USERS_PAGE } from '@/constants/users-page';

import {
  downloadCancelledOrderAttachment,
  uploadCancelledOrderAttachment,
  deleteCancelledOrderAttachment
} from '@/api/orders.api';
import { downloadFile, getFileFromUser, getLabelFromBoolean, taxFormatter } from '@/utils';

const ORDER_DETAILS_FIELD_LABEL = {
  [ORDER_FIELD.STATUS]: 'Order status',
  [ORDER_FIELD.ODOO_ORDER_ID]: 'Order Id in Odoo',
  [ORDER_FIELD.ODOO_ORDER_NAME]: 'Odoo Source name',
  [ORDER_FIELD.PURCHASE_DATE]: 'Order paid',
  [ORDER_FIELD.TRACKING_NUMBER_REGISTRATION]: 'Order sent',
  [ORDER_FIELD.DELIVERED_DATE]: 'Order delivered',
  [ORDER_FIELD.CUSTOMER_NAME]: 'Customer',
  [ORDER_FIELD.DOCTOR_NAME]: 'Doctor',
  [ORDER_FIELD.LOGISTIC_CENTER_NAME]: 'Logistic Center',
  [ORDER_FIELD.CARRIER]: 'Carrier',
  [ORDER_FIELD.TRACKING_NUMBER]: 'Tracking number',
  [ORDER_FIELD.CONSULTATION_MINUTES_SPENT]: 'Consultation time',
  [ORDER_FIELD.FEE]: 'Doctor fee',
  [ORDER_FIELD.TAXES]: 'Taxes',
  [ORDER_FIELD.APPLIED_PROMO_CODE]: 'Promocode',
  [ORDER_FIELD.APPLIED_PROMOCODE_AMMOUNT]: 'Promocode discount',
  [ORDER_FIELD.IS_TEST]: 'Is test order'
};

const defaultValueClasses = ['grey--text', 'text--darken-3'];

const LINKS = [
  ORDER_FIELD.CUSTOMER_NAME,
  ORDER_FIELD.DOCTOR_NAME,
  ORDER_FIELD.LOGISTIC_CENTER_NAME
];

const getValueClasses = (orderFieldName, fieldValue) => {
  if (orderFieldName === ORDER_FIELD.STATUS) {
    return [...defaultValueClasses, ORDER_DELIVERY_STATUS_CLASSES[fieldValue]];
  }

  if (LINKS.includes(orderFieldName)) {
    return ['light-blue--text', 'text--darken-4', 'font-weight-medium'];
  }

  return defaultValueClasses;
};

const ROUTE_BUILDER = {
  [ORDER_FIELD.DOCTOR_NAME]: id => ({ name: USERS_PAGE.USER_DETAILS, params: { id } }),
  [ORDER_FIELD.CUSTOMER_NAME]: id => ({ name: USERS_PAGE.CUSTOMER_DETAILS, params: { id } }),
  [ORDER_FIELD.LOGISTIC_CENTER_NAME]: id => ({
    name: USERS_PAGE.LOGISTIC_CENTER_DETAILS,
    params: { id }
  })
};

const ID_FIELD_NAME = {
  [ORDER_FIELD.DOCTOR_NAME]: ORDER_FIELD.DOCTOR_ID,
  [ORDER_FIELD.CUSTOMER_NAME]: ORDER_FIELD.PATIENT_ID,
  [ORDER_FIELD.LOGISTIC_CENTER_NAME]: ORDER_FIELD.LOGISTIC_CENTER_ID
};

const ITEMS_PER_COLUMN = 6;

const pushFieldToTable = (columns, field, itemIndex) => {
  const columnIndex = Math.floor(itemIndex / ITEMS_PER_COLUMN);
  const shouldCreateNewColumn = itemIndex % ITEMS_PER_COLUMN === 0;

  if (shouldCreateNewColumn) {
    return [...columns, { fields: [field], key: columnIndex }];
  }

  columns[columnIndex].fields.push(field);

  return columns;
};

const CURRENCY_FIELD_NAME = {
  [ORDER_FIELD.FEE]: ORDER_FIELD.FEE_CURRENCY_CODE,
  [ORDER_FIELD.APPLIED_PROMOCODE_AMMOUNT]: ORDER_FIELD.CURRENCY
};

const getPriceFormatter = orderFieldName => (orderFieldValue, order) => {
  const currency = order[CURRENCY_FIELD_NAME[orderFieldName]];

  if (!currency) {
    return '';
  }

  return new Intl.NumberFormat('en', { style: 'currency', currency }).format(orderFieldValue);
};

const dateFromatter = orderFieldValue =>
  orderFieldValue
    ? DateFormatService.formatDate(new Date(orderFieldValue), 'dd/MM/yyyy')
    : orderFieldValue;

const VALUE_FORMATTER = {
  [ORDER_FIELD.STATUS]: value => ORDERS_DELIVERY_STATUS_LABELS[value],
  [ORDER_FIELD.PURCHASE_DATE]: dateFromatter,
  [ORDER_FIELD.DELIVERED_DATE]: dateFromatter,
  [ORDER_FIELD.TRACKING_NUMBER_REGISTRATION]: dateFromatter,
  [ORDER_FIELD.CONSULTATION_MINUTES_SPENT]: minutes => (minutes ? `${minutes}min` : ''),
  [ORDER_FIELD.FEE]: getPriceFormatter(ORDER_FIELD.FEE),
  [ORDER_FIELD.APPLIED_PROMOCODE_AMMOUNT]: getPriceFormatter(ORDER_FIELD.APPLIED_PROMOCODE_AMMOUNT),
  [ORDER_FIELD.TAXES]: value => taxFormatter(value),
  [ORDER_FIELD.IS_TEST]: value => getLabelFromBoolean(value)
};

const ORDER_STATUSES_WITHOUT_CANCEL_ATTACHMENT = [
  ORDERS_DELIVERY_STATUSES.UNDER_PREPARATION,
  ORDERS_DELIVERY_STATUSES.RESEND_OPERATION,
  ORDERS_DELIVERY_STATUSES.DELIVERED
];

export default {
  name: 'OrderDetailsTab',
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  computed: {
    columns() {
      return Object.keys(ORDER_DETAILS_FIELD_LABEL)
        .map(name => {
          const rawValue = this.order[name];
          const value = this.getDisplayValue(name, rawValue);
          const isNone = !value;
          const valueClasses = getValueClasses(name, rawValue);
          const route = this.getRoute(name, rawValue, this.order);
          const comment =
            name === ORDER_FIELD.STATUS ? this.order[ORDER_FIELD.CANCELLED_COMMENT] : '';
          const hasAttachmentField =
            name === ORDER_FIELD.STATUS &&
            !ORDER_STATUSES_WITHOUT_CANCEL_ATTACHMENT.includes(this.order[ORDER_FIELD.STATUS]);

          return {
            label: ORDER_DETAILS_FIELD_LABEL[name],
            value,
            valueClasses,
            isNone,
            route,
            comment,
            hasAttachmentField
          };
        })
        .reduce(pushFieldToTable, []);
    },
    attachmentTitle() {
      return this.sholudDisplayRemoveAttachmentButton ? 'Download attachment' : 'Add attachment';
    },
    sholudDisplayRemoveAttachmentButton() {
      return this.order[ORDER_FIELD.HAS_ATTACHMENT];
    }
  },
  methods: {
    getRoute(key) {
      const routeBuilder = ROUTE_BUILDER[key];

      if (!routeBuilder) {
        return null;
      }

      const id = this.order[ID_FIELD_NAME[key]];

      return routeBuilder(id);
    },
    getDisplayValue(key, value) {
      const formatValue = pathOr(identity, [key], VALUE_FORMATTER);

      return formatValue(value, this.order);
    },
    onAttachmentClick() {
      if (this.order[ORDER_FIELD.HAS_ATTACHMENT]) {
        this.downloadAttachment();

        return;
      }

      this.uploadAttachment();
    },
    async uploadAttachment() {
      const file = await getFileFromUser();

      await uploadCancelledOrderAttachment({ orderId: this.order.orderId, file });
      this.onOrderUpdate();
    },
    async onDeleteAttachment() {
      await deleteCancelledOrderAttachment(this.order.orderId);
      this.onOrderUpdate();
    },
    async downloadAttachment() {
      const { data } = await downloadCancelledOrderAttachment(this.order.orderId);

      if (!data) {
        return;
      }

      downloadFile(data, 'attachment.pdf');
    },
    onOrderUpdate() {
      this.$emit('order-update');
    }
  }
};
</script>

<style lang="scss" scoped>
.tab {
  &__comment {
    max-width: 280px;
    font-size: 12px;
  }
}
</style>
