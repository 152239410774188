import { isEmpty } from 'ramda';

import { INGREDIENT_CONCENTRATIONS } from '@/constants/treatment';

export const getBottleDescription = bottle => {
  if (!bottle) {
    return null;
  }

  const { actif1, actif2, actif3 } = bottle;
  const activesArray = [actif1, actif2, actif3];

  const activesDescriptions = activesArray.reduce((actives, currentActive) => {
    if (!currentActive || currentActive.name === '') {
      return actives;
    }

    const { name, nbOfCapsules } = currentActive;
    const defaultConcentration = INGREDIENT_CONCENTRATIONS[name];
    const capsules = nbOfCapsules !== 1 ? `×${nbOfCapsules}` : '';

    return [...actives, `${name} ${defaultConcentration}%${capsules}`];
  }, []);

  const activesDescriptionText = isEmpty(activesDescriptions)
    ? 'no active ingredients'
    : activesDescriptions.join(', ');

  return activesDescriptionText;
};

export const getFullBottleDescription = (bottle, usage) => {
  if (!bottle) {
    return null;
  }

  const { actif1, actif2, actif3 } = bottle;
  const activesArray = [actif1, actif2, actif3];

  const activesDescriptions = activesArray.reduce((actives, currentActive) => {
    if (!currentActive || currentActive.name === '') {
      return actives;
    }

    const { name, nbOfCapsules } = currentActive;
    const defaultConcentration = INGREDIENT_CONCENTRATIONS[name];
    const capsules = nbOfCapsules !== 1 ? `×${nbOfCapsules}` : '';

    return [...actives, `${name} ${defaultConcentration}%${capsules}`];
  }, []);

  const activesDescriptionText = isEmpty(activesDescriptions)
    ? 'no active ingredients'
    : `Universkin Serum P (${activesDescriptions.join(', ')}), ${usage}, 15ml`;

  return activesDescriptionText;
};
