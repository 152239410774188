export default class CompositionProduct {
  constructor({ name, description, usage, price, percentage, amount, multiplier, isUpdated = false, isDeleted = false }) {
    this.name = name;
    this.description = description;
    this.usage = usage;
    this.price = price;
    this.percentage = percentage;
    this.amount = amount;
    this.multiplier = multiplier;
    this.isUpdated = isUpdated;
    this.isDeleted = isDeleted;
  }
}
