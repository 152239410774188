var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"tab d-flex justify-space-between"},_vm._l((_vm.columns),function(ref){
var fields = ref.fields;
var key = ref.key;
return _c('div',{key:key,staticClass:"tab__column mr-2"},_vm._l((fields),function(ref){
        var label = ref.label;
        var value = ref.value;
        var isNone = ref.isNone;
        var valueClasses = ref.valueClasses;
        var route = ref.route;
        var comment = ref.comment;
        var hasAttachmentField = ref.hasAttachmentField;
return _c('div',{key:label,staticClass:"mb-5"},[_c('div',{staticClass:"grey--text text--darken-1"},[_vm._v(_vm._s(label))]),(!isNone)?[(route)?_c('router-link',{staticClass:"text-decoration-none",class:valueClasses,attrs:{"to":route}},[_vm._v(" "+_vm._s(value)+" ")]):_c('div',{staticClass:"text-decoration-none",class:valueClasses},[_vm._v(" "+_vm._s(value)+" ")]),(comment)?_c('div',{staticClass:"font-italic mt-1 tab__comment"},[_vm._v(_vm._s(comment))]):_vm._e(),(hasAttachmentField)?_c('div',[_c('span',{staticClass:"light-blue--text text--darken-4 font-weight-medium text-decoration-underline mt-1 cursor-pointer",on:{"click":_vm.onAttachmentClick}},[_vm._v(" "+_vm._s(_vm.attachmentTitle)+" ")]),(_vm.sholudDisplayRemoveAttachmentButton)?_c('v-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"color":"red","size":"18px"},on:{"click":_vm.onDeleteAttachment}},[_vm._v("mdi-delete-forever")]):_vm._e()],1):_vm._e()]:_c('div',{staticClass:"grey--text text--lighten-1 font-italic"},[_vm._v("none")])],2)}),0)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }