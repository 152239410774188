<template>
  <update-info-modal
    width="420"
    modal-title="Cancel order"
    :has-close-button="false"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-slot:activator="{ on }">
      <slot name="activator" :on="on"></slot>
    </template>

    <template v-slot:modal-body>
      <v-form class="pt-1">
        <base-textarea v-model="comment" label="Comment" />

        <v-file-input
          v-model="attachment"
          placeholder="Add attachment"
          prepend-icon="mdi-file"
        ></v-file-input>
      </v-form>
    </template>

    <template v-slot:action-button>
      <v-btn class="white--text" color="red" outlined tile large @click="onCancelOrder">
        Cancel order
      </v-btn>
    </template>
  </update-info-modal>
</template>

<script>
import UpdateInfoModal from '@/components/common/UpdateInfoModal.vue';
import BaseTextarea from '@/components/common/BaseTextarea.vue';

import { cancelOrder } from '@/api/orders.api';

export default {
  name: 'CancelOrderModal',
  components: { UpdateInfoModal, BaseTextarea },
  props: {
    orderId: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      comment: '',
      attachment: null
    };
  },
  methods: {
    async onCancelOrder() {
      const params = {
        comment: this.comment,
        file: this.attachment,
        orderId: this.orderId
      };

      await cancelOrder(params);

      this.$emit('cancel-order');
    }
  }
};
</script>
