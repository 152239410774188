<template>
  <article class="order-details-page">
    <detail-page-template class="user-details-page blue-grey--text text--darken-3">
      <template v-slot:header>
        <details-page-header :header-info="headerInfo">
          <template v-slot:actions>
            <dots-dropdown :items="dotsMenuItems" @select-item="onDotsMenuOptionSelect" />
          </template>
        </details-page-header>
      </template>
      <template v-slot:content>
        <v-tabs color="blue-grey darken-3" height="30">
          <v-tab class="text-none">Details</v-tab>
          <v-tab class="text-none">Invoice lines</v-tab>
          <v-tab-item class="mt-8">
            <v-card flat tile>
              <v-card-text class="px-0">
                <order-details-tab :order="order" @order-update="loadOrderData" />
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item class="mt-8">
            <v-card flat tile>
              <v-card-text class="px-0">
                <invoice-details-tab :order="order" />
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </template>
    </detail-page-template>
    <cancel-order-modal
      :order-id="orderId"
      :value="isCancelOrderModalOpen"
      @close-modal="toggleCancelOrderModal(false)"
      @cancel-order="onCancelOrder"
    />

    <confirm-order-force-delivery
      :order-id="orderId"
      :value="isForceDeliveryModalOpen"
      @close-modal="toggleForceDeliveryModal(false)"
      @force-delivery-activated="onForceDeliveryActivated"
    />
  </article>
</template>

<script>
import { pathOr } from 'ramda';

import DetailPageTemplate from '@/components/tempates/DetailPageTemplate.vue';
import DetailsPageHeader from '@/components/common/DetailsPageHeader.vue';
import OrderDetailsTab from '@/components/order/OrderDetailsTab.vue';
import InvoiceDetailsTab from '@/components/order/InvoiceDetailsTab.vue';
import DotsDropdown from '@/components/users-page/DotsDropdown.vue';
import CancelOrderModal from '@/components/order/CancelOrderModal.vue';
import ConfirmOrderForceDelivery from '@/components/order/ConfirmOrderForceDelivery.vue';

import { getOrderById, resendOrder, markAsTestOrder } from '@/api/orders.api';

import { ORDER_FIELD, ORDERS_DELIVERY_STATUSES } from '@/constants/orders';

const DOTS_MENU_OPTION = {
  RESEND_ORDER: { id: 0, title: 'Re-send order' },
  CANCEL_ORDER: { id: 1, title: 'Cancel order' },
  MANUAL_ORDER_DELIVERY: { id: 2, title: 'Manual order delivery' },
  MARK_AS_TEST_ORDER: { id: 3, title: 'Mark as test order' },
  UNMARK_AS_TEST_ORDER: { id: 4, title: 'Unmark as test order' }
};

const HIDE_CANCEL_ORDER_OPTION_STATUSES = [
  ORDERS_DELIVERY_STATUSES.CANCELLED,
  ORDERS_DELIVERY_STATUSES.DELIVERED
];

export default {
  name: 'OrderDetailsPage',
  components: {
    ConfirmOrderForceDelivery,
    DetailPageTemplate,
    DetailsPageHeader,
    OrderDetailsTab,
    InvoiceDetailsTab,
    DotsDropdown,
    CancelOrderModal
  },
  data() {
    return {
      order: {},
      isCancelOrderModalOpen: false,
      isForceDeliveryModalOpen: false
    };
  },
  computed: {
    orderId() {
      return pathOr('', ['orderId'], this.order);
    },
    dotsMenuItems() {
      const status = pathOr('', [ORDER_FIELD.STATUS], this.order);
      const hideCancelOrderOption = HIDE_CANCEL_ORDER_OPTION_STATUSES.includes(status);
      const isTest = pathOr(false, [ORDER_FIELD.IS_TEST], this.order);

      const MARK_UNMARK_AS_TEST_OPTION = isTest
        ? DOTS_MENU_OPTION.UNMARK_AS_TEST_ORDER
        : DOTS_MENU_OPTION.MARK_AS_TEST_ORDER;

      if (status === ORDERS_DELIVERY_STATUSES.RESEND_OPERATION) {
        return [
          DOTS_MENU_OPTION.CANCEL_ORDER,
          DOTS_MENU_OPTION.MANUAL_ORDER_DELIVERY,
          MARK_UNMARK_AS_TEST_OPTION
        ];
      }

      return hideCancelOrderOption
        ? [DOTS_MENU_OPTION.MANUAL_ORDER_DELIVERY, MARK_UNMARK_AS_TEST_OPTION]
        : [
            DOTS_MENU_OPTION.RESEND_ORDER,
            DOTS_MENU_OPTION.CANCEL_ORDER,
            DOTS_MENU_OPTION.MANUAL_ORDER_DELIVERY,
            MARK_UNMARK_AS_TEST_OPTION
          ];
    },
    headerInfo() {
      return { id: this.orderId, name: 'Order' };
    }
  },
  async mounted() {
    await this.loadOrderData();
  },
  methods: {
    async onDotsMenuOptionSelect({ id }) {
      if (id === DOTS_MENU_OPTION.CANCEL_ORDER.id) {
        this.toggleCancelOrderModal(true);
        return;
      }

      if (id === DOTS_MENU_OPTION.RESEND_ORDER.id) {
        await this.resendOrder(this.order);

        return;
      }

      if (id === DOTS_MENU_OPTION.MANUAL_ORDER_DELIVERY.id) {
        this.toggleForceDeliveryModal(true);

        return;
      }

      if (id === DOTS_MENU_OPTION.MARK_AS_TEST_ORDER.id) {
        await this.markAsTestOrder(true);
      }

      if (id === DOTS_MENU_OPTION.UNMARK_AS_TEST_ORDER.id) {
        await this.markAsTestOrder(false);
      }

      await this.loadOrderData();
    },
    async markAsTestOrder(isTestOrder) {
      await markAsTestOrder(this.order.orderId, isTestOrder);
    },
    toggleCancelOrderModal(isOpen) {
      this.isCancelOrderModalOpen = isOpen;
    },
    toggleForceDeliveryModal(isOpen) {
      this.isForceDeliveryModalOpen = isOpen;
    },
    async resendOrder() {
      await resendOrder(this.order);

      await this.loadOrderData();
    },
    async onCancelOrder() {
      this.toggleCancelOrderModal(false);
      await this.loadOrderData();
    },
    async onForceDeliveryActivated() {
      this.toggleForceDeliveryModal(false);
      await this.loadOrderData();
    },
    async loadOrderData() {
      const orderId = this.$route.params.id;

      const { data } = await getOrderById(orderId);

      if (data) {
        this.order = data;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.order-details-page {
  height: 100%;
}
</style>
